.Container {
    width: 1100px;
    margin: 40px auto;
}

@media screen and (max-width: 1200px) {
    .Container {
        width: 100%;
        margin: 10px auto;
    }
}