.Backdrop {
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Backdrop img {
    width: 600px;
    height: 600px;
}