.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    width: 700px;
    height: auto;
    max-width: 90%;
}

.Modal * {
    width: 100%;
    height: 100%;
}

.Hide {
    display: none;
}